import { cloneDeep } from 'lodash';
import { nameFormatter } from '../shiftRotation/utils';
import { alphabeticalSort, getRegion } from '../utils/helpers';
import { getUpdatedProcessInfoWithAssociatesToFiveSProcessPath } from "./view/utils";

export const DEFAULT_SHIFT_ALLOCATION_MODEL = {
  associateInfo: {},
  processInfo: [],
  availableCycles: [],
  availableShifts: [],
  boardEntries: []
};

export const CLUSTER_TYPES = {
  CLUSTER: {
    type: 'SORT_CLUSTER',
    label: 'Cluster',
    isADTA: false
  },
  ADTA: {
    type: 'ADTA_CLUSTER',
    label: 'ADTA',
    isADTA: true
  }
};

export const EMPLOYEE_ICON_COLORS = {
  CLOCKED_IN: '#80ad2a',
  CLOCKED_OUT: '#6E777F',
  RECOMMENDED: '#0085b3',
  ISSUE: '#AD0A30'
};

export const PROCESS_PATHS = {
  INDUCT: 'Induct',
  STOW: 'Stow',
  PICK_TO_BUFFER: 'Pick to Buffer',
  PICK: 'Pick',
  STAGE: 'Stage',
  TRAINING: 'Training',
  ASL: 'ASL',
  DIVERTER: 'Diverter',
  AUTO_DIVERT_STRAIGHTENER: 'Auto Divert Straightener',
  INBOUND_DOCK: 'Inbound Dock W/S',
  SORT_PS: 'Sort Problem Solve',
  BENCH: 'Bench',
  ADTA_STOW: 'ADTA Container Building',
  Pusher: 'Pusher',
  UTR_OPS_SUPERVISOR: 'UTR OPS Supervisor / SA',
  NON_CON_MANUAL_HANDLING: 'Non-Con Manual Handling',
  NON_SCAN_STOW: 'Non-Scan Stow',
  YARD_MARSHALL: 'Yard Marshall',
  INDUCT_LINE_LOADER: 'Induct Line Loader',
  FIVE_S: '5S'
};

export const TRAINING_ALLOCATION_TYPES = {
  type1: 'type1',
  type2: 'type2',
  type3: 'type3'
};

function RingStatus(color, lineType) {
  this.color = color;
  this.lineType = lineType;
}
export function ThumbnailBadge(color, text) {
  this.color = color;
  this.text = text;
}

function Associate(
  alias,
  name,
  img,
  trainedPaths,
  recommendedPaths,
  disallowedPaths,
  ppr,
  ringStatus,
  clockedInStatus,
  associateId,
  thumbnailBadge,
  trackingPath
) {
  this.alias = alias;
  this.name = name;
  this.img = img;
  this.trainedPaths = trainedPaths;
  this.recommendedPaths = recommendedPaths;
  this.disallowedPaths = disallowedPaths;
  this.ppr = ppr;
  this.ringStatus = ringStatus;
  this.clockedInStatus = clockedInStatus;
  this.associateId = associateId;
  this.thumbnailBadge = thumbnailBadge;
}

export const ALLOCATION_TYPE = {
  PROCESS: 'PROCESS',
  TENURE: 'TENURE',
  AISLES: 'AISLES',
  CLUSTERS: 'CLUSTERS'
};

export const PROCESS_TYPE = {
  INDUCT_LINE: 'INDUCT_LINE',
  ASL_LINE: 'ASL_LINE',
  GROUP: 'PROCESS_GROUP',
  PROCESS: 'PROCESS',
  SORT_CLUSTER: 'SORT_CLUSTER',
  ADTA_CLUSTER: 'ADTA_CLUSTER',
  TRAINING: 'TRAINING_GROUP'
};

export const ALLOCATION_ALLOWED_STATION_CODES = [
  'DAU5',
  'DWA6',
  'DLX5',
  'DIL7',
  'DML4',
  'DML8',
  'DWI4',
  'DAT6',
  'DGE9',
  'DTP9',
  'DFT4',
  'DFL5',
  'DWS4',
  'DBI4',
  'DBI7',
  'DDN1',
  'DLU2',
  'DRM4',
  'DSS2',
  'DDD1',
  'DAU2',
  'DAU7',
  'DWA5',
  'DDX7',
  'DDX2',
  'DDX6',
  'DDT9',
  'DRT8',
  'DBM5',
  'DCL7',
  'DWA2',
  'DSW3'
];

export const MAX_CLUSTER_CHUNK_SIZE = 5;

export const EMPTY_PROCESS = 'EMPTY_PROCESS';

export const TIME_WINDOW_PHASES = {
  INDUCT_AND_STOW: 'INDUCT_AND_STOW',
  PICK_AND_STAGE: 'PICK_AND_STAGE'
};

export const processAvailableCycles = (timeWindowAllocations) => {
  const result = new Set();

  timeWindowAllocations.forEach((timeWindowAllocation) => {
    result.add(timeWindowAllocation.timeWindowAttrs.find((attr) => !!attr.cycle).cycle);
  });

  return [...result];
};

export const processAvailableShifts = (timeWindowAllocations) => {
  const result = new Set();

  timeWindowAllocations
    .forEach((timeWindowAllocation) => {
      result.add(JSON.stringify(timeWindowAllocation.timeWindow));
    });

  return [...result].map((cycle) => JSON.parse(cycle));
};

export const processAvailableCycleShifts = (timeWindowAllocations) => {
  const cycleShiftsMap = new Map();
  timeWindowAllocations.forEach((timeWindowAllocation) => {
    const cycle = timeWindowAllocation.timeWindowAttrs[0].cycle;
    const newShiftEntries = [...cycleShiftsMap.get(cycle) || [], timeWindowAllocation.timeWindow];
    cycleShiftsMap.set(cycle, newShiftEntries);
  });
  return cycleShiftsMap;
};

export const processAllocationData = (allocationBoard, associates, availableResourcesIds) => {
  const result = [];

  const associateInfo = {};

  associates.forEach((associate) => {
    if (!associateInfo[associate.id]) {
      associateInfo[associate.id] = new Associate(
        associate.id,
        associate.name,
        '',
        associate.trainedPaths,
        [associate.recommendedPath],
        associate.disallowedPaths,
        0,
        new RingStatus('Blue', 'solid'),
        false,
        associate.id,
      );
    }
  });

  const availableResourceSet = new Set(
    availableResourcesIds.filter((id) => {
      return !!associateInfo[id.toString()];
    })
  );

  allocationBoard.boardEntries.forEach((boardEntry) => {
    const assignments = allocationBoard.resourceAllocations.filter((resourceAllocation) => {
      return (
        resourceAllocation.boardEntity === boardEntry.id && availableResourceSet.has(resourceAllocation.resourceId)
      );
    });

    // removing the associates from the set to add the remaining
    // to the bench

    assignments.forEach((assignment) => {
      availableResourceSet.delete(assignment.resourceId);
    });
    result.push({
      ...boardEntry,
      assignments
    });
  });

  const benchAssignments = [...availableResourceSet].map((resource) => {
    return {
      resourceId: resource,
      boardEntity: PROCESS_PATHS.BENCH,
      allocationAttrs: {
        process: PROCESS_PATHS.BENCH
      }
    };
  });

  result.push({
    id: PROCESS_PATHS.BENCH,
    title: 'Bench',
    type: PROCESS_PATHS.BENCH,
    assignments: benchAssignments,
    processPathEntries: []
  });

  // result.push({
  //   id: PROCESS_PATHS.FIVE_S,
  //   title: '5S',
  //   type: PROCESS_PATHS.FIVE_S,
  //   assignments: [],
  //   processPathEntries: []
  // });

  return {
    processInfo: result,
    associateInfo,
    boardEntries: allocationBoard.boardEntries
  };
};

export const processAssociates = (data, recommendations) => {
  const processedAssociates = data.map((item) => {
    const paths = Object.keys(item.processPathAttributeMap).sort();
    const recommendation = recommendations.find((rec) => rec.resource.resourceId === item.resource.resourceId);

    const disallowedPaths = [];
    const trainedPaths = [];

    paths.forEach((path) => {
      if (item.processPathAttributeMap[path].eligibility) {
        if (item.processPathAttributeMap[path].eligibility.ineligibleCriteria) {
          if (
            item.processPathAttributeMap[path].eligibility.ineligibleCriteria.filter(
              (c) => c.reason !== 'TRAINING_INCOMPLETE'
            ).length > 0
          ) {
            disallowedPaths.push(path);
          }
        }

        if (
          item.processPathAttributeMap[path].eligibility.trainingProfile &&
          item.processPathAttributeMap[path].eligibility.trainingProfile.completedTraining
        ) {
          trainedPaths.push(path);
        }
      }
    });

    return {
      name: nameFormatter(item.resource.resourceName, getRegion()),
      id: item.resource.resourceId,
      disallowedPaths,
      riskRanking: item.riskRanking,
      trainedPaths,
      recommendedPath: recommendation && recommendation.recommendedProcess
    };
  });

  return processedAssociates.sort(alphabeticalSort('name'));
};

export const getTimeWindowAllocationKey = (timeWindowAllocation) => {
  const phase = timeWindowAllocation.timeWindowAttrs[0].phase;
  const cycle = timeWindowAllocation.timeWindowAttrs[0].cycle;
  const start = timeWindowAllocation.timeWindow.start;
  const end = timeWindowAllocation.timeWindow.end;
  return `${cycle}.${phase}.${start}.${end}`;
};

export const prepareModelForBackend = (fullModel, allocationBoards) => {
  const result = cloneDeep(fullModel);

  Object.keys(allocationBoards).forEach((boardKey) => {
    const [cycle, phase, start, end] = boardKey.split('.');
    const timeWindowAllocationIndex = fullModel.output.resourceAllocationOutput.timeWindowAllocations.findIndex(
      (twa) => {
        return (
          twa.timeWindowAttrs[0].phase === phase &&
          twa.timeWindowAttrs[0].cycle === cycle &&
          twa.timeWindow.start === start &&
          twa.timeWindow.end === end
        );
      }
    );

    const assignments = getUpdatedProcessInfoWithAssociatesToFiveSProcessPath(
      allocationBoards[boardKey].processInfo,
      allocationBoards[boardKey].associateInfo
    )
      .filter((process) => process.id !== PROCESS_PATHS.BENCH)
      .reduce((acc, boardEntry) => {
        return acc.concat(boardEntry.assignments);
      }, []);

    const newAllocationBoard = {
      ...fullModel.output.resourceAllocationOutput.timeWindowAllocations[timeWindowAllocationIndex].allocationBoard,
      boardEntries: allocationBoards[boardKey].boardEntries,
      resourceAllocations: assignments
    };

    result.output.resourceAllocationOutput.timeWindowAllocations[timeWindowAllocationIndex].allocationBoard =
      newAllocationBoard;

    result.input.sortLocationReservations = allocationBoards[boardKey].clusterDefinitions;
  });

  return result;
};
