import { ALLOCATION_TYPE, PROCESS_PATHS } from '../shiftAllocation/data';
import { getActualHeadcountsForBoardEntry, getRequiredHeadcountForBoardEntry } from '../shiftAllocation/view/utils';
import { SHIFT_ALLOCATION_ID_PREFIX, SHIFT_ALLOCATION_ID_SUFFIX } from './constants';

const removeMarginsForProcessCards = () => {
  const containers = document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}]`);
  containers.forEach((container, index) => {
    const child = container.children[0];
    child.style.width = '100%';
    child.style.marginBottom = '0px';
    child.style.marginRight = '0px';
  });
};

const removeMarginsForClusterCards = () => {
  const containers = document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}]`);
  containers.forEach((container, index) => {
    const child = container.children[0];
    child.style.marginBottom = '0px';
    child.style.marginRight = '0px';
  });
};

const fixWidthsForDraggable = () => {
  const containers = document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}]`);
  containers.forEach((container, index) => {
    container.setAttribute('style', 'width:60px !important');
  });
};

const fixWidthsForEmptyAssociates = () => {
  const containers = document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.EMPTY_ASSOCIATE_PREFIX}]`);
  containers.forEach((container, index) => {
    container.setAttribute('style', 'width:60px !important');
  });
};

const findParentByIdMatch = (element, idMatch) => {
  let ancestor = element.parentNode;
  while (ancestor && !ancestor.id.includes(idMatch)) {
    ancestor = ancestor.parentNode;
  }
  return ancestor;
};

const fixTransformForDraggable = (update, provided) => {
  if (update.destination) {
    let containers;
    let droppableContainer;
    if (!update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP)) {
      if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD)) {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      } else if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD)) {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      } else {
        droppableContainer = document.getElementById(`${PROCESS_PATHS.BENCH}`);
      }
    } else {
      if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD)) {
        const emptyIconContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
        droppableContainer = findParentByIdMatch(emptyIconContainer, SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD);
      } else {
        const emptyIconContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
        droppableContainer = findParentByIdMatch(emptyIconContainer, SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD);
      }
    }
    containers = Array.from(
      droppableContainer.querySelectorAll(
        `[id^=${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}]:not(#${SHIFT_ALLOCATION_ID_PREFIX.DRAGGABLE_ASSOCIATE}-${update.draggableId})`
      )
    );
    containers.forEach((container, index) => {
      container.setAttribute('style', 'transform: translate(0px, 0px) !important; width:60px !important');
    });
  }
};

const removeStylesFromDroppablesOnDragUpdate = () => {
  const containers = [
    ...document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}]`),
    ...document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}]`),
    ...document.querySelectorAll(`[id^=${PROCESS_PATHS.BENCH}]`)
  ];
  containers.forEach((container, index) => {
    container.removeAttribute('style', 'background-color:rgba(0,128,0, .2); border: 2px dashed green');
    if (container.querySelector('.labor-board-employee-icon-empty')) {
      container.querySelector('.labor-board-employee-icon-empty').removeAttribute('style', 'color: green');
      container.querySelector('.labor-board-employee-icon-empty-div').removeAttribute('style', 'border-color: green');
      container
        .querySelector('.labor-board-employee-icon-empty-div')
        .setAttribute('style', 'border: 2px Dashed #0085b3 !important');
    }
  });
};

const addBackgroundOnDroppable = (update, provided) => {
  if (update.destination) {
    if (!update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP)) {
      let droppableContainer;
      if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD)) {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      } else if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.CLUSTER_DETAIL_CARD)) {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      } else {
        droppableContainer = document.getElementById(`${PROCESS_PATHS.BENCH}`);
      }
      removeStylesFromDroppablesOnDragUpdate();
      droppableContainer.setAttribute('style', 'background-color:rgba(0,128,0, .2); border: 2px dashed green');
    } else {
      let droppableContainer;
      if (update.destination.droppableId.includes(SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD)) {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      } else {
        droppableContainer = document.getElementById(
          `${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${update.destination.droppableId}`
        );
      }
      removeStylesFromDroppablesOnDragUpdate();
      droppableContainer.querySelector('.labor-board-employee-icon-empty').setAttribute('style', 'color: green');
      droppableContainer
        .querySelector('.labor-board-employee-icon-empty-div')
        .setAttribute('style', 'border-color: green');
    }
  }
};

export const fixMarginAndWidth = () => {
  removeMarginsForProcessCards();
  removeMarginsForClusterCards();
  fixWidthsForDraggable();
  fixWidthsForEmptyAssociates();
};

export const fixStylesOnDragUpdate = (update, provided) => {
  fixTransformForDraggable(update, provided);
  addBackgroundOnDroppable(update, provided);
};

export const fixStylesOnDragEnd = (result, provided, processInfo) => {
  removeStylesFromDroppablesOnDragUpdate();
};

const addCardStaffingIssueStyles = (container, staffingIssue) => {
  if (container && staffingIssue) {
    if (staffingIssue.underStaffed) {
      container.setAttribute('style', 'background-color:rgba(254, 242, 184, .5); border: 2px dashed yellow');
    } else if (staffingIssue.overStaffed) {
      container.setAttribute('style', 'background-color:rgba(255, 204, 229, .5); border: 2px dashed pink');
    }
  }
};

const removeAllStaffingBackgroundStyles = () => {
  const containers = [
    ...document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}]`),
    ...document.querySelectorAll(`[id^=${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}]`),
    ...document.querySelectorAll(`[id^=${PROCESS_PATHS.BENCH}]`)
  ];
  containers.forEach((container, index) => {
    container.removeAttribute('style', 'background-color:rgba(254, 242, 184, .5); border: 2px dashed yellow');
    container.removeAttribute('style', 'background-color:rgba(255, 204, 229, .5); border: 2px dashed pink');
  });
};

const getStaffingIssue = (actualHeadCount, requiredHeadCount) => {
  return {
    overStaffed: actualHeadCount > requiredHeadCount,
    underStaffed: actualHeadCount < requiredHeadCount
  };
};

export const addStylesForStaffingIssues = (processInfo) => {
  removeAllStaffingBackgroundStyles();
  processInfo.forEach((boardEntry) => {
    if (boardEntry.allocationType !== ALLOCATION_TYPE.AISLES && boardEntry.id !== PROCESS_PATHS.BENCH) {
      const actualHeadCount = getActualHeadcountsForBoardEntry(boardEntry);
      const requiredHeadCount = getRequiredHeadcountForBoardEntry(boardEntry);
      const isStaffingIssue = getStaffingIssue(actualHeadCount, requiredHeadCount);
      if (isStaffingIssue.underStaffed || isStaffingIssue.overStaffed) {
        let containers = document.querySelectorAll(
          `[id^=${SHIFT_ALLOCATION_ID_PREFIX.PROCESS_CARD_ID_PREFIX}-${boardEntry.id}-${SHIFT_ALLOCATION_ID_SUFFIX.PROCESS_DETAIL_CARD}]`
        );
        if (!containers) {
          containers = document.querySelectorAll(
            `[id^=${SHIFT_ALLOCATION_ID_PREFIX.CLUSTER_CARD_ID_PREFIX}-${boardEntry.id}]`
          );
        }
        containers.forEach((container, index) => {
          if (!container.id.includes(SHIFT_ALLOCATION_ID_SUFFIX.RECOMMENDED_SPOT_DROP)) {
            addCardStaffingIssueStyles(container, isStaffingIssue);
          }
        });
      }
    }
  });
};
